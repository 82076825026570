import React, { useContext, useEffect, useMemo } from "react";
import ReactGA from "react-ga4";

import {
  Typography,
  Box,
  Grid,
  AppBar,
  Tabs,
  Autocomplete,
  TextField,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { Loader, SummaryLaneHeaders } from "../../../../../components/layout";
import QueryResult from "../../../../../components/containers/QueryResult";
import { BlankTabs } from "../../../../../components/layout/BlankTabs";
import SummaryCard, { SummaryHideCondition } from "./SummaryCard";

import useCompetitionSummary from "../../../../../hooks/useCompetitionSummary";

import { ScreenContext } from "../../../../../context/ScreenContext";

export default function Summary({
  eventView,
  roundHeatSubData,
  roundStatus,
  isCompetitionActive,
  summary_types,
  time_programId,
  eventLevel,
  showAge,
  summaryDataFetch,
}: {
  eventView: View;
  roundHeatSubData: Round | undefined;
  roundStatus: number | undefined;
  isCompetitionActive: boolean;
  summary_types: SummaryType[];
  time_programId?: number;
  eventLevel: number;
  showAge: boolean | undefined;
  summaryDataFetch: {
    summaryData: any;
    sortedRanks: Rank[];
    changeSummaryType: (newValue: number) => void;
    summaryTypeIndex: number;
    summaryTypeId: number | undefined;
    error: undefined;
    loading: boolean;
    summaryHideCondition: SummaryHideCondition;
  };
}) {
  const { screen } = useContext(ScreenContext);
  useMemo(() => {
    ReactGA.set({ page_title: `${screen} - Summary` });
  }, [screen]);

  const {
    summaryData,
    sortedRanks,
    changeSummaryType,
    summaryTypeIndex,
    error,
    loading,
    summaryHideCondition,
  } = summaryDataFetch;

  /* useMemo(() => {
    console.log('sortedRanks', sortedRanks)
  }, [sortedRanks]) */

  const handleTabListChange = (e: React.ChangeEvent, newValue: number) => {
    changeSummaryType(newValue);
  };

  const handleDropDownChange = (summaryType: SummaryType | null) => {
    if (!summaryType) {
      summary_types && summary_types[0]?.id && changeSummaryType(0);
    } else {
      const listIndex = summary_types.findIndex(
        (item) => item.id === summaryType.id
      );
      if (listIndex < 0) return changeSummaryType(0);
      else return changeSummaryType(listIndex);
    }
  };

  const event = summaryData?.summary_type_by_pk?.round?.event;
  const ranks = summaryData?.summary_type_by_pk?.ranks;
  const round = summaryData?.summary_type_by_pk?.round?.round_type;
  return (
    <>
      <Box mt={1}>
        {summary_types?.length > 1 && summary_types?.length < 6 ? (
          /* TABS */
          <AppBar
            position="static"
            color="transparent"
            sx={{ boxShadow: 0, width: "100%", height: "100%", mb: 1 }}
          >
            <StyledTabs
              //@ts-ignore
              value={summaryTypeIndex}
              onChange={handleTabListChange}
              centered
              sx={{ width: "100%" }}
            >
              {summary_types?.map((list) => (
                //@ts-ignore
                <BlankTabs key={list?.id} label={list?.name} />
              ))}
            </StyledTabs>
          </AppBar>
        ) : (
          summary_types?.length > 5 && (
            /* DROPDOWN */
            <Autocomplete
              id="summary_lists"
              disableClearable
              options={summary_types}
              //@ts-ignore
              getOptionLabel={(option) =>
                option.name != undefined && option.name
              }
              defaultValue={summary_types[0]}
              onChange={(e, newSummaryType) => {
                handleDropDownChange(newSummaryType);
              }}
              sx={{ mb: 1, mr: "8px", height: 28, cursor: "pointer" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  variant="outlined"
                  //@ts-ignore
                  color="neutral"
                  size="small"
                  placeholder="Select summary..."
                  autoComplete="off"
                  sx={{
                    height: 28,
                    "& :hover": { cursor: "pointer" },
                    "& #summary_lists": { height: 16, padding: 0 },
                    "& input::placeholder": {
                      fontSize: { xs: "0.75rem", sm: "1rem" },
                    },
                  }}
                />
              )}
            />
          )
        )}
        {ranks && ranks?.length === 0 ? (
          <Typography
            fontWeight={500}
            fontSize={{ xs: "1rem", sm: "1.2rem" }}
            py={1}
            mt={1}
            mx={0.5}
          >
            No competitors in this list
          </Typography>
        ) : (
          <>
            <Grid
              container
              sx={{
                borderRadius: 1,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#D0D5DD",
                bgcolor: "#FFF",
                boxShadow: 1,
              }}
            >
              <SummaryLaneHeaders
                isPara={event?.is_para_event}
                eventLevel={eventLevel}
                showAge={showAge}
              />
              <QueryResult
                onlyLoadInitially
                loading={true}
                data={summaryData}
                error={error}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {sortedRanks?.map((rank, index) => (
                      <SummaryCard
                        roundStatus={roundStatus}
                        key={rank?.id}
                        rank={rank?.rank}
                        lane={rank?.lane}
                        competitor={rank?.lane?.competitor}
                        index={index}
                        event={event}
                        eventLevel={eventLevel}
                        showAge={showAge}
                        round={round}
                        summaryHideCondition={summaryHideCondition}
                      />
                    ))}
                  </>
                )}
              </QueryResult>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))(({ theme }) => ({
  minHeight: 0,
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 50,
    marginTop: 0,
    paddingTop: 0,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 60,
    },
    width: "100%",
    backgroundColor: "#000",
  },
}));
